const schemaOrganization = {
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    name: 'Universal Metals',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '1020 Railroad St.',
      addressLocality: 'Corona',
      addressRegion: 'CA',
      postalCode: '92882',
      addressCountry: 'US',
    },
    alternateName: 'Arika Metals Inc',
    url: 'https://www.universalmetals.com/',
    logo: 'https://admin.universalmetals.com/wp-content/uploads/2017/07/umnewlogonew.png',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '1-951-271-3950',
        contactType: 'customer service',
      },
      {
        '@type': 'ContactPoint',
        telephone: '1-800-862-6159',
        contactType: 'sales',
      },
    ],
    sameAs: 'https://www.linkedin.com/company/universal-metals/',
  };

  export default schemaOrganization;