import React from 'react';

import { Grid, Box } from '@material-ui/core';

import Section from '../../section/section';
import Image from '../../image/image';
import ButtonWrap from '../../button-wrap/button-wrap';

import { getFeaturedImage } from '../../../utils/utils';

import './side-media.scss';

const classes = {
  title: { fontWeight: 700, margin: 0 },
  img: {
    width: '100%',
    boxShadow: '1px 4px 24px #00000061',
    border: '1px solid black',
    maxWidth: '90%',
    margin: 'auto',
    // display: 'block',
    borderRadius: '8px',
  },
  buttonWrap: {
    textAlign: 'center',
  },
};

function SideMedia({ index, imageSide, sectionContent, image, openModal }) {
  const featuredImage = getFeaturedImage(image);

  return (
    <Section
      index={index}
      className="section-3 sideMedia"
      style={{ padding: '100px 0 100px' }}
    >
      <div className="section-wrap">
        <Grid
          container
          spacing={5}
          direction={imageSide === 'right' ? 'row-reverse' : 'row'}
        >
          <Grid container item xs={12} md={6} alignItems="center">
            <Image alt={image.alt} title={image.title} style={classes.img} src={featuredImage} />
          </Grid>
          <Grid item xs={12} md={6} container alignItems="center">
            <Box>
              <h2 style={classes.title}>{sectionContent.sectionTitle}</h2>
              <div
                className="media-content"
                dangerouslySetInnerHTML={{
                  __html: sectionContent.sectionDescription,
                }}
              />
              <ButtonWrap onClickLeft={openModal} linkRight={'meh'} />
            </Box>
          </Grid>
        </Grid>
      </div>
    </Section>
  );
}

export default SideMedia;
