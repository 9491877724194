import React, { useRef } from 'react';

import Alert from '@material-ui/lab/Alert';
import { CircularProgress, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Button from '../button/button';

import './contact-form.scss';

const stylesInline = {
  title: {
    background: '#372f68',
    color: 'white',
    textAlign: 'center',
    fontSize: 25,
    padding: '10px 0',
  },
  form: {
    padding: '0 15px 16px',
  },
};

const ContactForm = ({
  messageSent,
  isSuccessMessage,
  isSubmitting,
  values,
  handleSubmit,
  handleChange,
  isFixed = true,
  classes,
  hideTitle = false,
}) => {
  const contactFormRef = useRef();

  if (messageSent) {
    return isSuccessMessage ? (
      <Alert style={{ padding: 20 }} severity="success">
        Message sent successfully!
      </Alert>
    ) : (
      <Alert style={{ padding: 20 }} severity="error">
        Something went wrong please try again.
      </Alert>
    );
  }

  if (isSubmitting) {
    return (
      <div style={{ padding: 20, textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div ref={contactFormRef} className="form-wrap">
      {!hideTitle && <h2 style={stylesInline.title}>Get Quote</h2>}
      <form style={stylesInline.form} onSubmit={handleSubmit}>
        <div>
          <TextField
            className={classes.inputRoot}
            label="Fullname"
            id="fullname"
            name="fullname"
            type="text"
            onChange={handleChange}
            value={values.fullname || ''}
            required
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            className={classes.inputRoot}
            label="Company Name"
            id="companyname"
            name="companyname"
            type="text"
            onChange={handleChange}
            value={values.companyname || ''}
            required
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            className={classes.inputRoot}
            label="Email"
            id="email"
            name="email"
            type="text"
            onChange={handleChange}
            value={values.email || ''}
            required
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            className={classes.inputRoot}
            label="Phone"
            id="phone"
            name="phone"
            type="tel"
            onChange={handleChange}
            value={values.phone || ''}
            required
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            className={classes.inputRoot}
            label="Message"
            multiline
            minRows={4}
            id="message"
            name="message"
            type="text"
            onChange={handleChange}
            value={values.message || ''}
            required
            variant="outlined"
          />
        </div>
        <div>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

const styles = theme => ({
  inputRoot: {
    width: '100%',
    marginBottom: 10,
    '& > fieldset': {
      background: '#efefef',
    },
  },
});

export default withStyles(styles)(ContactForm);
