import React from 'react';
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import ContactForm from '../components/contact-form/contact-form';
import Banner from '../components/banner/banner';
import schemaOrganization from '../configs/schemaOrganization';

const ContactPage = ({ formObj }) => {
  return (
    <div>
      <Helmet>
        <title>Contact Us - Universal Metals</title>
        <meta name="description" content="Get in touch with Universal Metals for inquiries, quotes, and more information about our services and products." />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrganization)}
        </script>
      </Helmet>
      <Banner title="Contact Us" />
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <img
                src="http://admin.universalmetals.com/wp-content/uploads/2017/07/Y9A2378-768x512.jpg"
                alt="Universal Metals"
                style={{
                  width: '100%',
                  boxShadow: 'rgba(0, 0, 0, 0.38) 1px 4px 24px',
                  border: '1px solid black',
                  maxWidth: '90%',
                  margin: '40px 40px 40px 15px',
                  borderRadius: '8px',
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p><br />
                <strong>Address:</strong> 
                <br /><a href="https://maps.app.goo.gl/VL2iyLud6u8b9VD88" target="_blank" rel="noopener noreferrer">1020 Railroad St.<br />Corona, CA 92882</a>
                <br /><br />
                Office Phone: <a href="tel:+19512713950">(951) 271-3950</a>
                <br />
                Toll free: <a href="tel:+18008626159">(800) 862-6159</a>
                <br />
                Fax: (951) 271-3940
              </p>
            </Grid>
            
          </Grid>
          <ContactForm {...formObj} hideTitle={true} />
          <br />
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactPage;