import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-parallax';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

import ReactGA from '../analytics';

import Jet from '../jet-test.jpg';

import Section from './section/section';
import ButtonWrap from './button-wrap/button-wrap';
import ImageSlider from './sections/image-slider/image-slider';

import PostCollection from './sections/post-collection/post-collection';
import { wp, envUrl } from '../configs/MetalConfig';
import { cache } from '../utils/utils';
import schemaOrganization from '../configs/schemaOrganization';

const namespace = 'wp/v2';
wp.service = wp.registerRoute(namespace, '/um_services/');

const MemoizedImageCards = React.memo(({ data }) => {
  return data.map((metal, i) => (
    <Grid
      key={i}
      item
      xs={6}
      md={4}
      lg
      // style={{ width: '20%', flex: '0 0 20%' }}
    >
      <Link to={metal.link}>
        <div className="content-wrap">
          <img
            alt={`${metal.metal} inventory`}
            style={{ width: '100%' }}
            src={metal.img}
          />

          <h2
            style={{ margin: 0 }}
            className="danzerpress-metals-title danzerpress-alloysteel wow slideInUp"
          >
            {metal.metal}
          </h2>
        </div>
      </Link>
    </Grid>
  ));
});

const deadArr = [];

function Home({ openModal, classes }) {
  const [services, setServices] = useState(cache.get('services') ?? deadArr);
  const [sliders, setSliders] = useState(cache.get('sliders') ?? deadArr);

  const getPosts = async () => {
    try {
      if (!services || services.length === 0) {
        const servicesPosts = await wp.service().embed();
        cache.save('services', servicesPosts);
        setServices(servicesPosts);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const getAcfSlider = () => {
    if (!sliders || sliders.length === 0) {
      wp.pages()
        .param('slug', 'home')
        .then(data => {
          cache.save(
            'sliders',
            data[0].acf.flexibleLayoutDanzerscript[0].gallery,
          );
          setSliders(data[0].acf.flexibleLayoutDanzerscript[0].gallery);
        });
    }
  };

  useEffect(() => {
    getPosts();
    getAcfSlider();
  }, []);

  const data = React.useMemo(
    () => [
      {
        metal: 'Titanium',
        img: `${envUrl}wp-content/uploads/2016/06/titanium.png`,
        link: 'inventory/titanium/',
      },
      {
        metal: 'Stainless Steel',
        img: `${envUrl}wp-content/uploads/2016/06/stainless-steel-flat-bar.png`,
        link: 'inventory/stainless-steel/',
      },
      {
        metal: 'Alloy Steel',
        img: `${envUrl}wp-content/uploads/2016/06/alloy.png`,
        link: 'inventory/alloy-steel/',
      },
      {
        metal: 'Nickel',
        img: `${envUrl}wp-content/uploads/2016/06/nickel.png`,
        link: 'inventory/nickel/',
      },
      {
        metal: 'Cobalt Alloy',
        img: `${envUrl}wp-content/uploads/2016/06/cobalt.png`,
        link: 'inventory/cobalt-alloy/',
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Universal Metals: Aerospace Metal Supplier & Inventory Management</title>
        <meta
          name="description"
          content="We are a next generation supplier of hard-metals. Our inventory Includes, Stainless Steel, Titanium, Alloy Steel, Cobalt Alloy, &amp; Nickel"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="googlebot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta
          name="bingbot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Home - Universal Metals" />
        <meta
          property="og:description"
          content="We are a next generation supplier of hard-metals. Our inventory Includes, Stainless Steel, Titanium, Alloy Steel, Cobalt Alloy, &amp; Nickel"
        />
        <meta property="og:url" content="/" />
        <meta property="og:site_name" content="Universal Metals" />
        <meta property="og:image" content="/wp-content/uploads/2017/07/5.jpg" />
        <meta property="og:image:width" content="2400" />
        <meta property="og:image:height" content="1462" />
        <meta name="twitter:card" content="summary" />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrganization)}
        </script>
      </Helmet>
      <Parallax
        strength={300}
        bgImage={Jet}
        // https://images.unsplash.com/photo-1498092651296-641e88c3b057?auto=format&fit=crop&w=1778&q=60&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D
        // bgImageStyle={{
        //   objectFit: 'cover',
        // }}
      >
        <Section className={classes.section1} index={1}>
          <div className="section-wrap" style={{ height: 400 }}>
            <div className={classes.insideStyles}>
              <h1 className={classes.heroTitle}>
                Specialty Metals
                <br /> And Services
              </h1>
              <p className={classes.heroDescription}>
                Committed to setting industry leading standards.
                <br />
                Quality cut-to-size, materials directly to your production line.
              </p>

              <ButtonWrap
                color="white"
                onClickLeft={openModal}
                linkRight={null}
              />
            </div>
          </div>
        </Section>
      </Parallax>

      <Section
        index={2}
        style={{
          // padding: '20px 0 40px',
          padding: '20px 0 20px',
        }}
        className="section-2"
      >
        <div
          className="section-wrap"
          style={{
            // maxWidth: 1400,
            maxWidth: 1200,
          }}
        >
          {/* <h2 style={{ marginTop: 0 }}>Inventory</h2> */}
          <div className="line"></div>
          <Grid container spacing={3}>
            <MemoizedImageCards data={data} />
          </Grid>
        </div>
      </Section>
      <PostCollection
        sectionTitle={'Services'}
        postCollection={services}
        index={3}
        className={['section-3', classes.postCollection].join(' ')}
        onClick={openModal}
      />
      <ImageSlider
        className="section-4"
        index={4}
        numberOfSlides={7}
        gallery={sliders}
      />
    </>
  );
}

const styles = theme => ({
  heroTitle: {
    margin: 0,
    lineHeight: 1,
    fontSize: 30,
    color: '#fff',
    [theme.breakpoints.up('lg')]: {
      fontSize: 45,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 40,
    },
  },
  heroDescription: {
    fontSize: 18,
    margin: '10px 0 20px',
    padding: '0 15px',
    [theme.breakpoints.up('lg')]: {
      fontSize: 22,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
  postCollection: { padding: '40px 0 100px' },
  insideStyles: {
    color: 'white',
    padding: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
    width: '100%',
    zIndex: 2,
    textShadow: 'rgba(0, 0, 0, 0.2) 0px 2px',
  },
  section1: { background: 'none !important', padding: 0 },
});

export default React.memo(withStyles(styles)(Home));
