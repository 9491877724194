import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import Image from '../image/image';

import './card.scss';

function Card({
  contentLink,
  link,
  img,
  title,
  description,
  skeletons = true,
  ...props
}) {
  const MainContent = (
    <div className="content-wrap" style={{ width: '100%' }}>
      <div className="image-wrap">
        <Image alt={title} src={img} title={title} />
      </div>
      <div className="services-wrap">
        <div className="title-wrap">
          {title ? (
            link ? (
              <Link to={link}>
                <h3>{title}</h3>
              </Link>
            ) : (
              <h3>{title}</h3>
            )
          ) : (
            <Skeleton variant="rect" animation="wave" height={47} />
          )}
        </div>

        {description ? (
          <div className="description-wrap">
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </div>
        ) : skeletons ? (
          <div className="description-wrap">
            <Skeleton
              style={{ marginRight: 10, marginBottom: 10 }}
              variant="rect"
              animation="wave"
              height={28}
              width="100%"
              // width={getRandomArbitrary(45, 85)}
            />
            <Skeleton
              style={{ marginRight: 10, marginBottom: 10 }}
              variant="rect"
              animation="wave"
              height={28}
              width="100%"
              // width={getRandomArbitrary(45, 85)}
            />
            <Skeleton
              style={{ marginRight: 10 }}
              variant="rect"
              animation="wave"
              height={28}
              width="100%"
              // width={getRandomArbitrary(45, 85)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );

  // Ensure the contentLink has a trailing slash
  const formattedContentLink = contentLink && !contentLink.endsWith('/') ? `${contentLink}/` : contentLink;

  const item = contentLink ? (
    <Link
      style={{ display: 'flex', flex: '0 1 auto', width: '100%' }}
      to={formattedContentLink}
    >
      {MainContent}
    </Link>
  ) : (
    MainContent
  );

  return (
    <Grid {...props} item container className="card">
      {item}
    </Grid>
  );
}

export default Card;
