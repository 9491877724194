import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Section from '../../section/section';
import Image from '../../image/image';

import './icons.scss';

function CardSection({ icons, index, sectionContent, classes }) {
  const { sectionTitle, sectionDescription } = sectionContent;

  return (
    <Section
      index={index}
      className="section-3"
      style={{ padding: '40px 0 100px' }}
    >
      <div className="section-wrap">
        {sectionTitle && (
          <h2 className={classes.sectionTitle}>{sectionTitle}</h2>
        )}
        {sectionDescription && (
          <div
            className={classes.sectionDescription}
            dangerouslySetInnerHTML={{ __html: sectionDescription }}
          />
        )}
        <Grid container spacing={4}>
          {icons.map(({ sectionContent, iconImage }, i) => (
            <Grid
              key={i}
              xs={12}
              sm={6}
              md={4}
              item
              className="icon"
              container
              alignContent="center"
            >
              <div
                className="content-wrap"
                style={{
                  background: '#fff',
                  padding: 20,
                  borderRadius: 8,
                  boxShadow: '1px 1px 21px #00000029',
                  width: '100%',
                  flex: '1 0 auto',
                  display: 'flex',
                  minHeight: 120,
                }}
              >
                {iconImage.url ? (
                  <div
                    className="image-wrap"
                    style={{ width: '100%', display: 'flex' }}
                  >
                    <Image
                      style={{
                        maxHeight: 70,
                        width: 'auto',
                        maxWidth: '80%',
                        height: 'auto',
                        margin: 'auto',
                        display: 'block',
                      }}
                      alt={iconImage.alt}
                      title={iconImage.title}
                      src={iconImage.url}
                    />
                  </div>
                ) : null}
                <div className="services-wrap">
                  <div className="title-wrap">
                    {sectionContent.sectionTitle && (
                      <Link>
                        <h4>{sectionContent.sectionTitle}</h4>
                      </Link>
                    )}
                  </div>
                  <div className="description-wrap">
                    {sectionContent.sectionDescription && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sectionContent.sectionDescription,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </Section>
  );
}

const styles = theme => ({
  sectionTitle: {
    marginBottom: 5,
  },
  sectionDescription: {
    '& p': {
      textAlign: 'center',
      margin: '0 0 26px',
    },
  },
  // image: {
  //   theme.
  // }
});

export default withStyles(styles)(CardSection);
